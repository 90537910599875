<template>
  <a-modal title="提示" @cancel="closeModal" :visible="show">
    <template slot="footer">
      <a-button key="back" @click="closeModal"> 取消 </a-button>
      <a-button key="submit" type="primary" @click="confirm">
        确定
      </a-button>
    </template>
    <a-input placeholder="请输入正确的token" v-model="token" />
  </a-modal>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      token: ''
    }
  },
  created() {},
  methods: {
    /**
     * 操作成功
     */
    confirm() {
      if (!this.token) {
        this.$message.warn('请输入token')
        return
      }
      this.$emit('close', this.token)
    },

    /**
     * 关闭弹出框
     */
    closeModal() {
      this.$emit('close')
    }
  },
  computed: {},
  watch: {}
}
</script>

<style lang="less" scoped></style>
