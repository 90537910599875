<template>
  <div v-if="showPage" class="home">
    <a-layout id="components-layout-demo-top-side-2">
      <!--  header -->
      <a-layout-header
        class="header"
        id="header"
        :style="{ position: 'fixed', zIndex: 1, width: '100%' }"
      >
        <div>
          <img style="width: 136px" src="../assets/logo.png" alt="" />
        </div>
        <a-divider style="height: 30px" type="vertical" />
        <div class="hepler-header">
          <span class="helper-text">帮助中心</span>

          <div class="helper-nav">
            <ul>
              <li
                v-for="(item, index) in moduleList"
                :key="index"
                :value="item.id"
              >
                <span
                  :class="checkedModule.id === item.id ? 'active' : ''"
                  @click="chooseModule(item)"
                >
                  {{ item.name }}
                </span>
                <a-divider
                  v-if="index === 0"
                  style="height: 30px"
                  type="vertical"
                />
              </li>
            </ul>
          </div>
        </div>
      </a-layout-header>
      <div
        v-if="!hasToken"
        class="container-token"
        style="margin-top: 64px; height: calc(100vh - 64px)"
      >
        <a-input class="input" placeholder="请输入正确的token" />
      </div>
      <a-layout
        v-else
        id="content"
        style="margin-top: 64px; height: calc(100vh - 64px)"
        :class="showSider ? '' : 'noAside'"
      >
        <a-layout-sider
          v-if="showSider"
          width="320"
          style="background: #fff; height: calc(100vh - 64px)"
        >
          <div class="add-directory-button" @click="showAddDirectoryModal">
            <span class="text">添加目录</span>
            <a-icon type="plus-circle" />
          </div>
          <a-divider
            type="horizontal"
            style="margin-top: 0; margin-bottom: 0"
          />
          <a-menu
            mode="inline"
            :openKeys="openKeys"
            :selectedKeys="selectedKeys"
            :style="{ height: '100%', borderRight: 0 }"
            @openChange="openFirstLevelDirectory"
          >
            <template v-for="(item, groupIndex) in directoryList">
              <a-menu-item
                v-if="item.children.length === 0"
                :key="item.id"
                :id="item.id"
                @mouseenter="mouseEnterDirectory(item.id)"
                @mouseleave="mouseLeaveDirectory()"
                @click="openSecondaryDirectory(item.name, item)"
              >
                <a-icon
                  type="arrow-up"
                  @click="updateFirstLevelSorts(groupIndex, 'up')"
                  :style="
                    'visibility:' +
                    (groupIndex !== 0 && mouseFocusOnDirectoryId === item.id
                      ? 'visible'
                      : 'hidden')
                  "
                />
                <a-icon
                  type="arrow-down"
                  @click="updateFirstLevelSorts(groupIndex, 'down')"
                  :style="
                    'visibility:' +
                    (groupIndex !== directoryList.length - 1 &&
                    mouseFocusOnDirectoryId === item.id
                      ? 'visible'
                      : 'hidden')
                  "
                />
                {{ item.name }}
                <a-icon
                  type="edit"
                  class="menu-edit-level2"
                  @click.stop="showEditDirectoryModal(item)"
                  :style="
                    'visibility:' +
                    (mouseFocusOnDirectoryId === item.id ? 'visible' : 'hidden')
                  "
                />
              </a-menu-item>
              <a-sub-menu v-else :key="item.id">
                <div
                  slot="title"
                  class="menu-title"
                  @mouseenter="mouseEnterDirectory(item.id)"
                  @mouseleave="mouseLeaveDirectory()"
                >
                  <a-icon
                    type="arrow-up"
                    @click="updateFirstLevelSorts(groupIndex, 'up')"
                    :style="
                      'visibility:' +
                      (groupIndex !== 0 && mouseFocusOnDirectoryId === item.id
                        ? 'visible'
                        : 'hidden')
                    "
                  />
                  <a-icon
                    type="arrow-down"
                    @click="updateFirstLevelSorts(groupIndex, 'down')"
                    :style="
                      'visibility:' +
                      (groupIndex !== directoryList.length - 1 &&
                      mouseFocusOnDirectoryId === item.id
                        ? 'visible'
                        : 'hidden')
                    "
                  />
                  <span :id="item.id">{{ item.name }}</span>
                  <a-icon
                    type="edit"
                    class="menu-edit-level1"
                    @click.stop="showEditDirectoryModal(item)"
                    :style="
                      'visibility:' +
                      (mouseFocusOnDirectoryId === item.id
                        ? 'visible'
                        : 'hidden')
                    "
                  />
                </div>
                <a-menu-item
                  v-for="(directory, index) in item.children"
                  :key="directory.id"
                  :id="directory.id"
                  @mouseenter="mouseEnterDirectory(directory.id)"
                  @mouseleave="mouseLeaveDirectory()"
                  @click="openSecondaryDirectory(item.name, directory)"
                >
                  <a-icon
                    type="arrow-up"
                    @click="updateSecondaryLevelSorts(groupIndex, index, 'up')"
                    :style="
                      'visibility:' +
                      (index !== 0 && mouseFocusOnDirectoryId === directory.id
                        ? 'visible'
                        : 'hidden')
                    "
                  />
                  <a-icon
                    type="arrow-down"
                    @click="
                      updateSecondaryLevelSorts(groupIndex, index, 'down')
                    "
                    :style="
                      'visibility:' +
                      (index !== item.children.length - 1 &&
                      mouseFocusOnDirectoryId === directory.id
                        ? 'visible'
                        : 'hidden')
                    "
                  />
                  {{ directory.name }}
                  <a-icon
                    type="edit"
                    class="menu-edit-level2"
                    @click.stop="showEditDirectoryModal(directory)"
                    :style="
                      'visibility:' +
                      (mouseFocusOnDirectoryId === directory.id
                        ? 'visible'
                        : 'hidden')
                    "
                  />
                </a-menu-item>
              </a-sub-menu>
            </template>
          </a-menu>
        </a-layout-sider>
        <a-layout style="padding: 24px 24px 0">
          <div>
            <a-layout-content
              :style="{
                background: '#fff',
                padding: '24px',
                margin: 0,
                minHeight: '670px'
              }"
              style="overflow-y: overlay; text-align: left"
            >
              <router-view @operateFromChild="operateFromChild"></router-view>
            </a-layout-content>
            <a-layout-footer style="text-align: center">
              © {{ nowYear }} 嘿住科技
            </a-layout-footer>
          </div>
        </a-layout>
      </a-layout>
    </a-layout>
    <addDirectoryModal
      :show="showAddDirectoryModalFlag"
      :directoryList="directoryList"
      :moduleId="checkedModule.id"
      :directory="needEditDirectory"
      @close="closeAddDirectoryModal"
    />
    <inputTokenModal
      :show="showInputTokenModalFlag"
      @close="closeInputTokenModal"
    />
  </div>
</template>

<script>
import { getDirectoriesList, getModuleList, updateDirectorySorts } from '@/api'
import local from '@/utils/local'
import addDirectoryModal from '../components/addDirectoryModal.vue'
import inputTokenModal from '../components/inputTokenModal.vue'
export default {
  name: 'HomeView',
  components: { addDirectoryModal, inputTokenModal },
  data() {
    return {
      // 没有token显示输入token
      hasToken: true,
      showPage: false,
      showSider: true,
      collapsed: false,
      nowYear: new Date().getFullYear(),
      // 模块列表
      moduleList: [],
      // 选中的模块
      checkedModule: null,
      // 目录列表
      directoryList: [],
      // 展开的一级目录
      openKeys: [],
      // 查看文章的目录
      selectedKeys: [],
      // 鼠标焦点目录id
      mouseFocusOnDirectoryId: '',
      // 是否显示添加目录对话框
      showAddDirectoryModalFlag: false,
      // 需要编辑的类目
      needEditDirectory: null,
      // 是否显示输入token框
      showInputTokenModalFlag: false
    }
  },
  created() {},
  mounted() {
    this.getModuleList()
    this.$forceUpdate()
  },
  methods: {
    back() {
      if (this.$route.path.indexOf('index') !== -1) {
        return false
      }
      this.$router.push('/index')
    },

    // 获取模块列表
    async getModuleList() {
      const data = await getModuleList()
      if (data.code === 'FAQ-20000') {
        const moduleList = data.data.modules
        moduleList.unshift({
          id: 'faq',
          name: '常见问题',
          order: 1,
          type: 'collection'
        })
        this.moduleList = moduleList
        this.$store.state.moduleList = moduleList
        this.chooseModule(moduleList[1])
      }
    },

    /**
     * 显示首页
     */
    showDirectoryPage() {
      this.initDirectoryList()
      if (this.$route.path.indexOf('/manage/index') !== -1) {
        return false
      }
      this.$router.push('/manage/index')
    },

    /**
     * 初始化目录
     */
    async initDirectoryList() {
      this.directoryList = []
      const data = await getDirectoriesList(this.checkedModule.id)
      if (!data || data.code !== 'FAQ-20000') {
        return
      }
      const originalList = data.data.directories
      const list = []
      // 取一级目录
      originalList.forEach((item) => {
        if (!item.parentId) {
          list.push(item)
        }
      })
      // 取二级目录
      list.forEach((item) => {
        item.children = originalList.filter((originalItem) => {
          return originalItem.parentId && originalItem.parentId === item.id
        })
      })
      this.directoryList = list
      const checkedDirectory = this.getCheckedDirectory()
      this.openKeys = [checkedDirectory.openKey]
      this.selectedKeys = [checkedDirectory.selectedKey]
      this.$store.state.manage_directoryId = checkedDirectory.selectedKey
      this.showPage = true
      this.$forceUpdate()
    },

    /**
     * 获取默认选择的目录，
     * 如果原来没有
     */
    getCheckedDirectory() {
      let openKey = ''
      const directoryList = this.directoryList
      if (directoryList.length === 0) {
        return {
          openKey,
          selectedKey: ''
        }
      }
      let selectedKey
      // 如果没有已选择的，那么选择第一个
      const firstDirectory = directoryList[0]
      // 如果有子目录，选择子目录第一个
      if (firstDirectory.children.length > 0) {
        selectedKey = firstDirectory.children[0].id
        openKey = firstDirectory.id
        return {
          openKey,
          selectedKey
        }
      }
      // 如果没有子目录选择一级目录第一个
      openKey = firstDirectory.id
      selectedKey = firstDirectory.id
      return {
        openKey,
        selectedKey
      }
    },

    /**
     * 选择模块
     */
    chooseModule(module) {
      this.checkedModule = module
      if (module.type === 'collection') {
        this.$router.push('/manage/faq')
        return
      }
      this.showDirectoryPage()
      // if (this.$route.path.indexOf('article') === -1) {
      //   this.$router.push('/article')
      // }
    },

    /**
     * 展开一级目录
     * openKeyList 展开的目录id，我们只展开一个，所以只取最后一个
     */
    openFirstLevelDirectory(openKeyList) {
      console.log('openKeyList', openKeyList)
      if (openKeyList.length === 0) {
        this.openKeys = []
        return false
      }
      // 只取最后一个
      const openKey = openKeyList[openKeyList.length - 1]
      this.openKeys = [openKey]
      // 打开的目录
      const openDirectory = this.directoryList.find((item) => {
        return item.id === openKey
      })
      this.$store.state.manage_directoryId = openKey
      this.selectedKeys = [openKey]
    },

    /**
     * 鼠标进入某个目录范围
     * @param {目录id} directoryId
     */
    mouseEnterDirectory(directoryId) {
      this.mouseFocusOnDirectoryId = directoryId
    },

    /**
     * 鼠标离开某个目录范围
     */
    mouseLeaveDirectory() {
      this.mouseFocusOnDirectoryId = ''
    },

    /**
     * 更新一级目录排序
     * @param {需要修改的index} index
     * @param {up:上移,down:下移} type
     */
    async updateFirstLevelSorts(index, type) {
      const step = type === 'down' ? 1 : -1
      // 目标位置
      const destinationIndex = index + step
      // 需要移动的目录
      const source = this.directoryList[index]
      // 目标位置当前的目录
      const destination = this.directoryList[destinationIndex]
      this.directoryList[destinationIndex] = source
      this.directoryList[index] = destination

      const sorts = []
      this.directoryList.forEach((item, index) => {
        sorts.push({
          directoryId: item.id,
          sortNum: index
        })
      })
      const data = await updateDirectorySorts({ sorts })
      if (data.code !== 'FAQ-20000') {
        this.$message.error(data.detail || 设置失败)
        return
      }
      this.$message.success('设置成功')
      // this.initDirectoryList()
    },

    /**
     * 更新二级目录排序
     * @param {一级目录} goupIndex
     * @param {需要修改的index} index
     * @param {up:上移,down:下移} type
     */
    async updateSecondaryLevelSorts(goupIndex, index, type) {
      console.log('goupIndex', goupIndex)
      const step = type === 'down' ? 1 : -1
      // 目标位置
      const destinationIndex = index + step
      const list = this.directoryList[goupIndex].children
      // 需要移动的目录
      const source = list[index]
      // 目标位置当前的目录
      const destination = list[destinationIndex]
      list[destinationIndex] = source
      list[index] = destination
      const sorts = []
      list.forEach((item, index) => {
        sorts.push({
          directoryId: item.id,
          sortNum: index
        })
      })
      const data = await updateDirectorySorts({ sorts })
      if (data.code !== 'FAQ-20000') {
        this.$message.error(data.detail || 设置失败)
        return
      }
      this.$message.success('设置成功')
      // this.initDirectoryList()
    },

    /**
     *
     * 点击二级目录查看文章列表
     */
    openSecondaryDirectory(firstLevelName, directory) {
      const id = directory.id
      this.selectedKeys = [id]
      this.$store.state.manage_directoryId = id
      this.$forceUpdate()
    },

    /**
     * 显示编辑类目对话框
     */
    showEditDirectoryModal(directory) {
      this.needEditDirectory = directory
      this.showAddDirectoryModalFlag = true
      console.log('showAddDirectoryModalFlag', this.showAddDirectoryModalFlag)
    },

    /**
     * 显示添加类目对话框
     */
    showAddDirectoryModal() {
      this.needEditDirectory = null
      this.showAddDirectoryModalFlag = true
      console.log('showAddDirectoryModalFlag', this.showAddDirectoryModalFlag)
    },

    /**
     * 关闭添加类目对话框
     */
    closeAddDirectoryModal(type) {
      if (type === 'success') {
        this.showDirectoryPage()
      }
      this.showAddDirectoryModalFlag = false
    },

    /**
     * 子路由操作
     */
    operateFromChild(type) {
      if (type === 'showInputTokenModal') {
        this.showInputTokenModalFlag = true
      }
    },

    /**
     * 关闭token输入框
     */
    closeInputTokenModal(token) {
      if (token) {
        local.set('token', token)
      }
      this.showInputTokenModalFlag = false
    }
  },
  computed: {
    isShowSider() {
      // 只有首页显示目录
      return this.$route.path.indexOf('manage/index') >= 0
    }
  },
  watch: {
    isShowSider: {
      deep: true,
      handler: function (n, o) {
        this.showSider = n
      }
    }
  }
}
</script>
<style lang="less" scoped>
.overHeader {
  position: absolute;
  width: 1080px;
}
.searchIcon {
  position: absolute;
  top: 7px;
  left: 10px;
}
#content {
  min-width: 1080px;
  overflow-x: initial;
}
#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}
.ant-layout-header {
  background: #ffffff;
  box-shadow: 0px 4px 10px 1px rgba(9, 71, 178, 0.12);
  opacity: 1;
  padding: 0 36px;
}
.header {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  position: 'fixed';
  z-index: 1;
  width: 100%;
  min-width: 1080px;

  .hepler-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;

    .helper-text {
      font-size: 18px;
      font-family: 'PingFang SC-中等', 'PingFang SC';
      font-weight: 500;
      color: #399cd0;
    }

    .helper-nav {
      display: flex;
      align-items: center;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          span {
            border-radius: 20px;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            padding: 0 15px;
            margin-left: 35px;
            font-size: 18px;
            font-weight: normal;
            color: #222a54;
          }

          span:hover {
            background-color: #f6f7f8;
            color: #284ad1;
          }
        }
      }
    }
  }
}
.active {
  background-color: #f6f7f8;
  color: #284ad1 !important;
}

/deep/.ant-menu-submenu-title {
  text-align: left;
}
.ant-menu-item {
  text-align: left;
  // color: #222a54;
}

.add-directory-button {
  cursor: pointer;
  height: 60px;
  line-height: 60px;
}

.add-directory-button .text {
  font-size: 18px;
  font-weight: 900;
  margin-right: 10px;
}

.menu-title {
  font-size: 16px;
  // color: #222a54;
  height: 50px;
  font-weight: 900;
  line-height: 50px;
  display: flex;
  align-items: center;
}

.ant-menu-item {
  display: flex;
  align-items: center;
}

.menu-sort-view {
  width: 60px;
}

.menu-edit-level1 {
  position: absolute;
  right: 40px;
}

.menu-edit-level2 {
  position: absolute;
  right: 10px;
  margin: auto;
}

.noAside {
  padding: 0 240px;
}

//  搜索
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
.certain-category-search-wrapper
  .certain-category-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input-suffix {
  right: 12px;
}
.certain-category-search-wrapper .certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}
.certain-category-search-wrapper
  .certain-category-search.ant-select-focused
  .certain-category-icon {
  color: #108ee9;
}
.certain-category-search-wrapper .certain-category-icon {
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}

.container-token {
  padding: 50px;
  background-color: white;
  .input {
    height: 50px;
  }
}
</style>
