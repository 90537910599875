<template>
  <div v-show="showPage" class="home">
    <a-layout id="components-layout-demo-top-side-2">
      <!--  header -->
      <a-layout-header
        class="header"
        id="header"
        :style="{ position: 'fixed', zIndex: 1, width: '100%' }"
      >
        <div>
          <img style="width: 136px" src="../assets/logo.png" alt="" />
        </div>
        <a-divider style="height: 30px" type="vertical" />
        <div class="hepler-header">
          <span class="helper-text">帮助中心</span>
          <div class="helper-nav">
            <!-- 搜索 -->
            <div class="global-search-wrapper" style="width: 300px">
              <a-auto-complete
                class="global-search"
                size="large"
                style="width: 100%"
                placeholder="搜索文章标题"
                option-label-prop="title"
                @select="showArticle"
                @search="handleSearch"
              >
                <template slot="dataSource">
                  <a-select-option
                    v-for="item in dataSource"
                    :key="item.id"
                    :title="item.title"
                  >
                    <span v-html="item.title"></span>
                  </a-select-option>
                </template>
                <div style="position: relative">
                  <a-input
                    id="inputId"
                    placeholder="搜索文章标题"
                    style="border-radius: 20px; padding: 0 40px"
                  />
                  <!-- <a-icon class="searchIcon" type="search" /> -->
                  <svg
                    t="1656931045535"
                    class="icon searchIcon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1630"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M926.293333 865.92l-120.746666-120.746667a406.4 406.4 0 1 0-60.373334 60.373334l120.746667 120.746666a42.666667 42.666667 0 0 0 60.373333-60.373333zM490.666667 810.666667a320 320 0 1 1 320-320 320 320 0 0 1-320 320z"
                      p-id="1631"
                      fill="#222a54"
                    ></path>
                    <path
                      d="M256 533.333333a42.666667 42.666667 0 0 1-42.666667-42.666666 277.333333 277.333333 0 0 1 277.333334-277.333334 42.666667 42.666667 0 0 1 0 85.333334 192 192 0 0 0-192 192 42.666667 42.666667 0 0 1-42.666667 42.666666z"
                      p-id="1632"
                      fill="#222a54"
                    ></path>
                  </svg>
                </div>
              </a-auto-complete>
            </div>
            <ul>
              <li
                :class="!inHomePage ? item.act : ''"
                v-for="(item, index) in moduleList"
                :key="index"
                :value="item.id"
                @click="chooseModule(item.id)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
      </a-layout-header>
      <a-layout
        id="content"
        style="height: calc(100vh - 64px)"
        :style="{ marginTop: '64px' }"
        :class="!inHomePage ? '' : 'noAside'"
      >
        <a-layout-sider
          v-if="!inHomePage"
          width="200"
          style="background: #fff; height: calc(100vh - 64px)"
        >
          <a-menu
            mode="inline"
            :openKeys="openKeys"
            :selectedKeys="selectedKeys"
            :style="{ height: '100%', borderRight: 0 }"
            @openChange="openFirstLevelDirectory"
          >
            <template v-for="item in directoryList">
              <a-menu-item
                v-if="item.children.length === 0"
                :key="item.id"
                :id="item.id"
                @click="openSecondaryDirectory(item.name, item, false)"
              >
                {{ item.name }}
              </a-menu-item>
              <a-sub-menu v-else :key="item.id">
                <div slot="title" class="menu-title">
                  <span>{{ item.name }}</span>
                </div>
                <a-menu-item
                  v-for="directory in item.children"
                  :key="directory.id"
                  :id="directory.id"
                  @click="openSecondaryDirectory(item.name, directory, true)"
                >
                  {{ directory.name }}
                </a-menu-item>
              </a-sub-menu>
            </template>

            <!-- <a-sub-menu v-for="item in directoryList" :key="item.id">
              <span
                v-if="item.length !== 0"
                slot="title"
                class="menu-title"
                :id="item.id"
                >{{ item.name }}</span
              >
              <a-menu-item
                v-for="directory in item.children"
                :key="directory.id"
                :id="directory.id"
                @click="openSecondaryDirectory(item.name, directory)"
              >
                {{ directory.name }}
              </a-menu-item>
            </a-sub-menu> -->
          </a-menu>
        </a-layout-sider>
        <a-layout style="padding: 0 24px 24px">
          <div>
            <a-layout-content
              :style="{
                background: inHomePage ? '' : '#fff',
                padding: '24px',
                margin: 0,
                minHeight: '670px'
              }"
              style="overflow-y: overlay; text-align: left"
            >
              <a-breadcrumb v-if="!inHomePage" style="margin: 16px 0">
                <a-breadcrumb-item>
                  <a v-if="breadcrumb.secondLevel" @click="backFirstLevel">{{
                    breadcrumb.firstLevel
                  }}</a>
                  <span v-else>{{ breadcrumb.firstLevel }}</span>
                </a-breadcrumb-item>
                <a-breadcrumb-item v-if="breadcrumb.secondLevel">
                  <a v-if="breadcrumb.articleName" @click="backSecondLevel">{{
                    breadcrumb.secondLevel
                  }}</a>
                  <span v-else>{{ breadcrumb.secondLevel }}</span>
                </a-breadcrumb-item>
                <a-breadcrumb-item v-if="breadcrumb.articleName">{{
                  breadcrumb.articleName
                }}</a-breadcrumb-item>
              </a-breadcrumb>
              <router-view></router-view>
            </a-layout-content>
            <a-layout-footer style="text-align: center">
              © {{ nowYear }} 嘿住科技
            </a-layout-footer>
          </div>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { getDirectoriesList, getModuleList, searchArticle } from '@/api'
import local from '@/utils/local'
export default {
  name: 'HomeView',
  data() {
    return {
      nowYear: new Date().getFullYear(),
      breadcrumb: {},
      showPage: false,
      // 是否显示目录
      inHomePage: false,
      collapsed: false,
      dataSource: [],
      moduleId: '',
      moduleList: [], // 模块列表
      directoryList: [], // 目录列表
      defaultOpenkeys: [],
      isUpdate: false,
      uplateArticleTitle: '',
      selectedKeys: [],
      openKeys: [],
      screenWidth: ''
    }
  },
  created() {},
  mounted() {
    this.getModuleList()
    this.$forceUpdate()
  },
  methods: {
    async handleSearch(value) {
      if (!value) {
        this.dataSource = []
        return
      }
      const params = {
        q: value
      }
      const data = await searchArticle(params)
      if (data.code !== 'FAQ-20000') {
        this.$message.error(data.detail)
        return
      }
      if (data.data.results.length === 0) {
        this.dataSource = [{ title: '找不到结果', id: '1' }]
        return
      }
      this.dataSource = data.data.results
      this.dataSource.forEach((item) => {
        item.title = item.title.replaceAll(value, `<a>${value}</a>`)
      })
    },

    /**
     * 查看文章
     */
    showArticle(value) {
      this.$router.push({
        path: '/article/detail',
        query: { id: value }
      })
    },

    /**
     * 查看文章
     */
    articleInfo(id) {
      // 当前不是详情页面，跳转到详情页面
      if (this.$route.path.indexOf('article/detail') !== -1) {
        this.$router.push({
          path: '/article/detail',
          query: { id }
        })
        return
      }
      this.$store.state.showArticleId = id
    },

    /**
     * 返回一级目录
     */
    backFirstLevel() {
      // if (this.$route.path.indexOf('index') !== -1) {
      //   return
      // }
      // this.$router.push('/index')
    },

    /**
     * 返回二级目录列表
     */
    backSecondLevel() {
      if (this.$route.path.indexOf('index') !== -1) {
        return
      }
      this.$router.push('/index')
    },

    // 获取模块列表
    async getModuleList() {
      const data = await getModuleList()
      if (data.code === 'FAQ-20000') {
        const moduleList = data.data.modules
        moduleList.forEach((item) => {
          item.act = ''
        })
        this.moduleList = moduleList
        this.showPage = true
        this.$store.state.moduleList = moduleList
        // 如果不在首页并且没有选择模块，那么默认选择第一个模块
        if (!this.inHomePage && !this.$store.state.moduleId) {
          this.$store.state.moduleId = moduleList[0].id
        }
      }
    },

    /**
     * 初始化目录
     */
    async initDirectoryList(moduleId, parentId) {
      this.directoryList = []
      const data = await getDirectoriesList(moduleId, parentId)
      if (!data || data.code !== 'FAQ-20000') {
        return
      }
      const originalList = data.data.directories
      const list = []
      // 取一级目录
      originalList.forEach((item) => {
        if (!item.parentId) {
          list.push(item)
        }
      })
      // 取二级目录
      list.forEach((item) => {
        item.children = originalList.filter((originalItem) => {
          return originalItem.parentId && originalItem.parentId === item.id
        })
      })
      // // 只取有二级目录的
      // this.directoryList = list.filter((item) => {
      //   return item.children.length > 0
      // })
      this.directoryList = list
      const checkedDirectory = this.getCheckedDirectory()
      this.showCheckedDirectory(checkedDirectory)
    },

    /**
     * 显示选择的目录
     */
    showCheckedDirectory(checkedDirectory) {
      this.openKeys = [checkedDirectory.openKey]
      this.selectedKeys = [checkedDirectory.selectedKey]
      this.breadcrumb.firstLevel = checkedDirectory.firstLevel
      this.breadcrumb.secondLevel = checkedDirectory.secondLevel
      if (this.$store.state.directoryId !== checkedDirectory.selectedKey) {
        this.$store.state.directoryId = checkedDirectory.selectedKey
      }
      // this.$forceUpdate()
    },

    /**
     * 获取默认选择的目录，
     * 如果原来没有
     */
    getCheckedDirectory() {
      let openKey = ''
      let firstLevel = ''
      let secondLevel = ''
      const directoryList = this.directoryList
      if (directoryList.length === 0) {
        return {
          firstLevel,
          secondLevel,
          openKey,
          selectedKey: ''
        }
      }

      let selectedKey = this.$store.state.directoryId
      if (selectedKey) {
        for (let index = 0; index < directoryList.length; index++) {
          const item = directoryList[index]
          // 如果一级目录就和上次选择的匹配，那么选择一级目录
          if (item.id === selectedKey) {
            openKey = item.id
            firstLevel = item.name
            break
          }
          for (let index = 0; index < item.children.length; index++) {
            const directory = item.children[index]
            if (directory.id === selectedKey) {
              openKey = item.id
              firstLevel = item.name
              secondLevel = directory.name
              break
            }
          }
          if (openKey) {
            break
          }
        }
        if (openKey) {
          return {
            firstLevel,
            secondLevel,
            openKey,
            selectedKey
          }
        }
      }

      // 如果没有已选择的，那么选择第一个
      const firstDirectory = directoryList[0]
      // 如果有二级目录，选择子目录第一个
      if (firstDirectory.children.length > 0) {
        selectedKey = firstDirectory.children[0].id
        openKey = firstDirectory.id
        firstLevel = firstDirectory.name
        secondLevel = firstDirectory.children[0].name
        return {
          firstLevel,
          secondLevel,
          openKey,
          selectedKey
        }
      }
      // 如果没有子目录那么选择一级目录
      openKey = firstDirectory.id
      selectedKey = firstDirectory.id
      firstLevel = firstDirectory.name
      return {
        firstLevel,
        secondLevel,
        openKey,
        selectedKey
      }
    },

    /**
     * 展开一级目录
     * openKeyList 展开的目录id，我们只展开一个，所以只取最后一个
     */
    openFirstLevelDirectory(openKeyList) {
      if (openKeyList.length === 0) {
        this.openKeys = []
        return false
      }
      // 只取最后一个展开的
      const openKey = openKeyList[openKeyList.length - 1]
      this.openKeys = [openKey]
      // 展开的目录
      const openedDirectory = this.directoryList.find((item) => {
        return item.id === openKey
      })
      if (openedDirectory.children.length === 0) {
        this.$store.state.directoryId = openKey
        this.breadcrumb.firstLevel = item.name
        this.breadcrumb.secondLevel = ''
        this.selectedKeys = [openKey]
      }
    },

    /**
     *
     * 点击二级目录查看文章列表
     */
    openSecondaryDirectory(firstLevelName, directory, isChildDirectory) {
      const id = directory.id
      this.selectedKeys = [id]
      this.$store.state.directoryId = id
      if (firstLevelName !== this.breadcrumb.firstLevel) {
        this.breadcrumb.firstLevel = firstLevelName
      }
      // 如果是子目录，那么显示二级目录名
      this.breadcrumb.secondLevel = isChildDirectory ? directory.name : ''
      if (this.$route.path.indexOf('index') === -1) {
        this.$router.push('/index')
      }
      this.$store.state.directoryId = id
    },

    /**
     * 点击tab栏
     * @param {模块id} id
     */
    chooseModule(id) {
      this.$store.state.moduleId = id
    },

    /**
     * 显示模块
     * @param {模块id} id
     */
    showModule(id) {
      this.moduleId = id
      if (!id) {
        return
      }
      this.initDirectoryList(id)
      if (this.inHomePage) {
        this.$router.push('/index')
      }
      this.moduleList.forEach((item) => {
        item.act = id === item.id ? 'active' : ''
      })
    }
  },
  computed: {
    isInHomePage() {
      // 只有home页不显示目录
      return this.$route.path.indexOf('/home') >= 0
    },
    getShowArticleName() {
      // home页不显示目录
      return this.$store.state.showArticleName
    }
  },
  watch: {
    isInHomePage: {
      deep: true,
      immediate: true,
      handler: function (n, o) {
        this.inHomePage = n
        if (n) {
          // 进入home页清空数据
          this.$store.state.moduleId = ''
          this.$store.state.directoryId = ''
          this.breadcrumb = {}
        }

        // this.$forceUpdate()
      }
    },
    getShowArticleName: {
      deep: true,
      handler: function (n, o) {
        this.breadcrumb.articleName = n
        this.$forceUpdate()
      }
    },
    '$store.state.moduleId': {
      deep: true,
      handler: function (n, o) {
        this.showModule(n)
      }
    },
    '$store.state.latestDirectory': {
      deep: true,
      handler: function (newValue, oldValue) {
        this.$store.state.directoryId = newValue.directoryId
        const checkedDirectory = this.getCheckedDirectory()
        this.showCheckedDirectory(checkedDirectory)

        // // 如果模块更改了
        // if (this.$store.state.moduleId !== newValue.moduleId) {
        //   this.$store.state.directoryId = newValue.directoryId
        //   this.$store.state.moduleId = newValue.moduleId
        //   return
        // }
        // // 如果模块一样但是目录不一样，修改目录
        // // 用了
        // if (this.$store.state.directoryId !== article.directoryId) {
        //   this.$store.state.sameModuleOtherDirectoryId = article.directoryId
        // }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.overHeader {
  position: absolute;
  width: 1080px;
}
.searchIcon {
  position: absolute;
  top: 7px;
  left: 10px;
}
#content {
  min-width: 1080px;
  overflow-x: initial;
}
#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
}
.ant-layout-header {
  background: #ffffff;
  box-shadow: 0px 4px 10px 1px rgba(9, 71, 178, 0.12);
  opacity: 1;
  padding: 0 36px;
}
.header {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  position: 'fixed';
  z-index: 1;
  width: 100%;
  min-width: 1080px;

  .hepler-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;

    .helper-text {
      font-size: 18px;
      font-family: 'PingFang SC-中等', 'PingFang SC';
      font-weight: 500;
      color: #399cd0;
    }

    .helper-nav {
      display: flex;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          border-radius: 20px;
          height: 40px;
          cursor: pointer;
          padding: 0 15px;
          margin-left: 35px;
          font-size: 18px;
          font-weight: normal;
          color: #222a54;
        }

        li:hover {
          background-color: #f6f7f8;
          color: #284ad1;
        }
      }
    }
  }
}
.active {
  background-color: #f6f7f8;
  color: #284ad1 !important;
}

/deep/.ant-menu-submenu-title {
  text-align: left;
}
.ant-menu-item {
  text-align: left;
  // color: #222a54;
}
.ant-breadcrumb {
  text-align: left;
}

.menu-title {
  font-size: 16px;
  // color: #222a54;
  height: 50px;
  font-weight: 900;
  line-height: 50px;
  display: inline-block;
}
.noAside {
  padding: 0 240px;
}

//  搜索
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
.certain-category-search-wrapper
  .certain-category-search.ant-select-auto-complete
  .ant-input-affix-wrapper
  .ant-input-suffix {
  right: 12px;
}
.certain-category-search-wrapper .certain-search-item-count {
  position: absolute;
  color: #999;
  right: 16px;
}
.certain-category-search-wrapper
  .certain-category-search.ant-select-focused
  .certain-category-icon {
  color: #108ee9;
}
.certain-category-search-wrapper .certain-category-icon {
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}
</style>
