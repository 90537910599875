import local from '@/utils/local'
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import manageView from '../views/manageView.vue'
import PriceSign from '../views/priceSign.vue'

Vue.use(VueRouter)

const dd = {}
function isMobileDevice() {
  // let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(userAgent)
}

const routes = [
  {
    path: '/priceSign',
    name: '电子房价牌',
    component: PriceSign
  },
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: HomeView,
    children: [
      {
        path: '/home',
        meta: { name: '首页' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/mobile/home.vue'))
          )
      },
      {
        path: '/index',
        meta: { name: '首页' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/index.vue'))
          )
      },
      {
        path: '/article/detail',
        meta: { name: '文章详情' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/articleDetail.vue'))
          )
      },
      {
        path: '/article/list',
        meta: { name: '文章详情' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/articleUplateList.vue'))
          )
      },
      {
        path: '/article/add',
        meta: { name: '新增文章' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/editArticle.vue'))
          )
      },
      {
        path: '/article/update',
        meta: { name: '修改文章' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/editArticle.vue'))
          )
      }
    ]
  },
  {
    path: '/manage',
    name: 'manage',
    redirect: '/manage/index',
    component: manageView,
    children: [
      {
        path: '/manage/index',
        meta: { name: '首页' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/manage/index.vue'))
          )
      },
      {
        path: '/manage/faq',
        meta: { name: '常见问题' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/manage/faq.vue'))
          )
      },
      {
        path: '/manage/add',
        meta: { name: '新增文章' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/manage/edit.vue'))
          )
      },
      {
        path: '/manage/update',
        meta: { name: '修改文章' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/manage/edit.vue'))
          )
      },
      {
        path: '/manage/addVideo',
        meta: { name: '新增视频' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/manage/videoEdit.vue'))
          )
      },
      {
        path: '/manage/updateVideo',
        meta: { name: '修改视频' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/manage/videoEdit.vue'))
          )
      },
      {
        path: '/manage/test',
        meta: { name: '修改视频' },
        component: (resolve) =>
          require.ensure([], () =>
            resolve(require('../views/article/manage/videoTest.vue'))
          )
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
