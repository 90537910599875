import Vue from 'vue'
import Vuex from 'vuex'
import local from '../utils/local.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    flag: true,
    moduleList: '',
    moduleId: '',
    directoryId: '',
    // 最新的模块或者目录
    latestDirectory: {},
    showArticleName: '',
    manage_directoryId: ''
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
})
