/*
  注册了全局组件
*/

// 在当前页面禁用 eslint 代码检查
/* eslint-disable */

import Vue from 'vue'

// 注册一个全局的面板
import catalogueSelect from '../components/catalogueSelect.vue'
Vue.component('catalogueSelect', catalogueSelect)
// 注册一个全局的面板
import test from '../components/test.vue'
Vue.component('test', test)