<template>
  <div>
    <a-tree-select
      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
      :tree-data="treeData"
      :placeholder="placeholder"
      :defaultValue="directoryId"
      @select="selectChange"
      treeNodeLabelProp="label"
    >
    </a-tree-select>
  </div>
</template>
<script>
import { getDirectoriesList } from '@/api'
export default {
  props: {
    placeholder: {
      default: '请选择目录',
      type: String
    },

    directoryId: {
      type: String
    }
  },
  data() {
    return {
      treeData: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const moduleList = this.$store.state.moduleList
      const treeData = []
      moduleList.forEach((module) => {
        if (module.type !== 'collection') {
          treeData.push({
            key: module.id,
            value: module.id,
            title: module.name,
            label: module.name,
            isLeaf: false,
            selectable: false
          })
        }
      })
      for (let index = 0; index < treeData.length; index++) {
        const item = treeData[index]
        // 根据模块id获取模块下的目录
        item.children = await this.getDirectoryList(item.key, item.title)
      }
      this.treeData = treeData
    },

    /**
     * 获取模块下的目录
     * @param {模块id} moduleId
     */
    async getDirectoryList(moduleId, mouduleName) {
      const list = []
      const data = await getDirectoriesList(moduleId)
      if (!data || data.code !== 'FAQ-20000') {
        return list
      }
      const originalList = data.data.directories
      // 取一级分类
      originalList.forEach((item) => {
        if (!item.parentId) {
          list.push({
            key: item.id,
            value: item.id,
            title: item.name,
            label: mouduleName + '/' + item.name,
            isLeaf: false,
            selectable: false
          })
        }
      })
      // 取二级分类
      list.forEach((item) => {
        const children = []
        for (let index = 0; index < originalList.length; index++) {
          const child = originalList[index]
          if (child.parentId && child.parentId === item.key) {
            children.push({
              key: child.id,
              value: child.id,
              title: child.name,
              label: item.label + '/' + child.name,
              isLeaf: true,
              selectable: true
            })
          }
        }
        item.children = children
        if (children.length === 0) {
          item.isLeaf = true
          item.selectable = true
        }
      })
      return list
    },

    /**
     * 选择回调
     */
    selectChange(v, node) {
      this.$emit('selectId', v)
    }
  },
  computed: {},
  watch: {}
}
</script>

<style lang="less" scoped>
/deep/.ant-select {
  min-width: 200px !important;
}
</style>
