<template>
  <!-- :confirm-loading="showConfirmLoadingFlag"
    @ok="addDirectoryConfirm"
    @cancel="closeModal" -->
  <a-modal
    :title="directory ? '编辑分类' : '添加分类'"
    :visible="show"
    @cancel="closeModal('cancel')"
  >
    <template slot="footer">
      <a-button
        key="delete"
        type="link"
        class="delete-dirrctory"
        :loading="deleteLoading"
        @click="showDeleteDirectoryTipsModal"
      >
        删除目录
      </a-button>
      <a-button key="back" @click="closeModal('cancel')"> 取消 </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="showConfirmLoadingFlag"
        @click="addDirectoryConfirm"
      >
        确定
      </a-button>
    </template>

    <a-form-model :model="addDirectoryInfo" ref="test" :label-col="{ span: 5 }">
      <a-form-model-item label="分类层级">
        <a-radio-group v-model="addDirectoryInfo.level">
          <a-radio value="level1">一级目录</a-radio>
          <a-radio value="level2">二级目录</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="分类名称">
        <div>
          <a-select
            v-if="addDirectoryInfo.level !== 'level1'"
            class="add-dirrctory-parent"
            v-model="addDirectoryInfo.parentId"
            placeholder="请选择一级分类"
          >
            <a-select-option
              v-for="item in directoryList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-input
            class="add-dirrctory-name"
            placeholder="请输入分类名"
            v-model="addDirectoryInfo.name"
          />
        </div>
      </a-form-model-item>
      <!-- <a-form-model-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button type="primary" html-type="submit"> Submit </a-button>
      </a-form-model-item> -->
    </a-form-model>
  </a-modal>
</template>

<script>
import { addDirectory, editDirectory, deleteDirectory } from '@/api'
import { submit } from 'dom7'
import { array } from 'snabbdom'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    // 模块id
    moduleId: {
      type: String
    },
    // 需要编辑的目录
    directory: {
      type: Object
    },
    // 一级目录以及其子目录
    directoryList: {
      type: Array
    }
  },
  data() {
    return {
      deleteLoading: false,
      showConfirmLoadingFlag: false,
      // 添加分类信息
      addDirectoryInfo: {}
    }
  },
  created() {
    this.addDirectoryInfo = { level: 'level1', name: '' }
  },

  mounted() {},
  methods: {
    /**
     * 添加分类确定
     */
    async addDirectoryConfirm() {
      const addDirectoryParams = this.getAddDirectoryParams()
      // 等于0代表有异常
      if (addDirectoryParams.code === 0) {
        this.$message.warn(addDirectoryParams.message)
        return
      }
      this.showConfirmLoadingFlag = true
      try {
        await this.submitDirectory(addDirectoryParams.data)
        this.showConfirmLoadingFlag = false
        this.operateSuccess()
      } catch (error) {
        console.log('confrimErro', error)
        this.showConfirmLoadingFlag = false
        this.$message.warn('保存失败')
      }
    },

    /**
     * 添加的参数
     */
    getAddDirectoryParams() {
      const addDirectoryInfo = this.addDirectoryInfo
      const params = {}
      // 当需要添加的分类是二级分类的时候必须选择一级分类
      if (addDirectoryInfo.level === 'level2') {
        if (!addDirectoryInfo.parentId) {
          return {
            code: 0,
            message: '请选择一级分类'
          }
        }
        params.parentId = addDirectoryInfo.parentId
      }
      if (!addDirectoryInfo.name) {
        return {
          code: 0,
          message: '请输入分类名'
        }
      }
      params.name = addDirectoryInfo.name
      params.order = this.getDirectoryOrder(params)
      params.moduleId = this.moduleId
      return {
        code: 1,
        data: params
      }
    },

    /**
     * 获取添加的目录的排序
     */
    getDirectoryOrder(params) {
      const oldDirectory = this.directory
      // 如果有directory，那么就是编辑类目
      if (oldDirectory) {
        // 以下两种情况排序order不变
        // 1.如果原来是一级目录，现在也是一级目录，那么order不变
        if (!oldDirectory.parentId && !params.parentId) {
          return oldDirectory.order
        }
        // 2.如果原来是二级目录，现在也是二级目录，并且parentId一样，那么order不变
        if (
          oldDirectory.parentId &&
          params.parentId &&
          oldDirectory.parentId === params.parentId
        ) {
          return oldDirectory.order
        }
      }
      if (!params.parentId) {
        return this.getAddDirectoryOrder(this.directoryList)
      }
      const parentDirectory = this.directoryList.find((item) => {
        return item.id === params.parentId
      })
      return this.getAddDirectoryOrder(parentDirectory.directories)
    },

    /**
     * 获取添加目录的排序值
     */
    getAddDirectoryOrder(directoryList) {
      if (directoryList.length === 0) {
        return 0
      }
      return Math.max(...directoryList.map((item) => item.order)) + 1
    },

    /**
     * 提交数据
     */
    async submitDirectory(params) {
      // console.log('directory', this.directory)
      // 没有directory，那么是新增
      if (!this.directory) {
        await addDirectory(params)
        return
      }
      // 有directory，那么编辑
      await editDirectory(this.directory.id, params)
    },

    /**
     * 显示删除提示框
     */
    async showDeleteDirectoryTipsModal() {
      const that = this
      this.$confirm({
        title: '提示',
        content: '确定删除目录？',
        async onOk() {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
          // }).catch(() => console.log('Oops errors!'))

          return await that.deleteDirectory()
        },
        onCancel() {}
      })
    },
    /**
     * 删除目录
     */
    async deleteDirectory() {
      await deleteDirectory(this.directory.id)
      this.operateSuccess()
    },

    /**
     * 操作成功
     */
    operateSuccess() {
      this.closeModal('success')
    },

    /**
     * 关闭弹出框
     */
    closeModal(type) {
      this.$emit('close', type)
    }
  },
  computed: {
    getDirectory() {
      return this.directory
    }
  },
  watch: {
    getDirectory: {
      deep: true,
      immediate: true,
      handler: function (n, v) {
        if (!n) {
          this.addDirectoryInfo = {
            level: 'level1'
          }
          return
        }
        const addDirectoryInfo = {
          name: n.name
        }
        if (n.parentId) {
          addDirectoryInfo.parentId = n.parentId
          addDirectoryInfo.level = 'level2'
        } else {
          addDirectoryInfo.level = 'level1'
        }
        this.addDirectoryInfo = addDirectoryInfo
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add-dirrctory-parent {
  width: 200px !important;
  margin-right: 10px;
}

.add-dirrctory-name {
  width: 200px !important;
}

.delete-dirrctory {
  color: red;
  display: inline-block;
  position: absolute;
  left: 0;
}

.delete-dirrctory:hover {
  color: #ff000099;
}
</style>
