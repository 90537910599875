import store from '../store'
import axios from 'axios'
import qs from 'qs'
import local from '../utils/local.js'

// 添加请求拦截器
axios.interceptors.request.use(function(config) {
  config.headers.Accept = 'application/json'
  return config
})

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code === 206) {
      return response
    }
    // 处理请求200的操作，默认不需要操作，可直接返回 return 返回正确信息调用接口时可以直接promise then 取到正确信息
    // if (response.data.code === 403) {
    //   alert('没有权限，请联系管理员授权')
    // }
    if (response.data.code === 10000006) {
      local.set('scrmHotelPermissions', 0)
      store.state.scrmHotelPermissions = 0
      return response
    }
    if (
      response.data.code === 10000000 ||
        response.data.code === 0 ||
        response.data.authStatus === 'AUTH-SERVER-20200'
    ) {
      // console.log(response.data)
    } else {
      // console.log(response.data)
    }
    store.state.scrmHotelPermissions = 1
    local.set('scrmHotelPermissions', 1)
    return response
  },
  error => {
    console.log(error.response)
    if (!error.response) {
      console.log('网络超时')
      return Promise.reject(error)
    }
    // 处理状态码操作
    switch (error.response.status) {
      case 400:
        console.log('参数错误')
        break
      case 401:
        // local.clear()
        // localStorage.clear()
        // window.location.href = process.env.VUE_APP_API_LOGINURL
        break
      case 500:
        console.log('空指针异常')
        break
      case 501:
        console.log('空指针异常')
        break
      default:
        return Promise.reject(error)
    }
    // return error 返回错误
    return error
  }
)

// 请求方法
export default {
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  put(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, params, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  downloadGet(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { responseType: 'blob' })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  delete(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          url,
          { params },
          {
            headers: {
              Accept: 'application/json'
            }
          }
        )
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  postUser(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(params), {
          headers: {
            Accept: 'application/json'
          },
          timeout: 5 * 60 * 1000
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  postLogin(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(params), {
          headers: {
            'Accept-Access-Token-Type': 'delicate',
            Accept: 'application/json'
          },
          timeout: 5 * 60 * 1000
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(params), {
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  uploadForm(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: {
            token: local.get('token')
            // 'Content-Type': 'multipart/form-data'
          },
          timeout: 10 * 60 * 1000
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  from(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, qs.stringify(params), {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  fromPost(url, params) {
    let time = 5 * 1000
    if (url.indexOf('upload') !== -1) {
      time = 6 * 60 * 1000
    }
    return new Promise((resolve, reject) => {
      axios
        .post(url, qs.stringify(params), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
