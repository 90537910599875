var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type == 1 && _vm.bgc)?_c('div',{staticClass:"content",style:(_vm.bgc === 'black'
          ? 'background-color: rgba(19, 29, 29, 1)'
          : {
              'background-image': `url(${require(`../assets/${_vm.bgc}.png`)}`
            })},[_c('div',{staticClass:"contatiner"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title",class:_vm.bgc === 'orange' ? '' : 'white-word'},[_vm._v(" "+_vm._s(_vm.showInfo.hotelName)+" ")]),_c('div',{staticClass:"theader",class:_vm.headerColor},[_c('div',{staticStyle:{"width":"57%","text-align":"left"}},[_vm._v("房型")]),_c('div',{staticStyle:{"width":"20%","text-align":"right"}},[_vm._v("今日价")]),_c('div',{staticStyle:{"width":"23%","text-align":"right"}},[_vm._v("会员价")])]),_c('div',{staticClass:"list"},[_c('div',{class:_vm.showInfo && _vm.showInfo.roomTypes && _vm.showInfo.roomTypes.length >= 6
                  ? 'rowup'
                  : ''},[_c('ul',{staticClass:"roomUl"},_vm._l((_vm.showInfo.roomTypes),function(item){return _c('li',{key:item.roomTypeId,class:_vm.liColor},[_c('div',{staticStyle:{"width":"57%","text-align":"left"}},[_vm._v(" "+_vm._s(item.roomTypeName)+" ")]),_c('div',{staticStyle:{"width":"20%","text-align":"right"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"memberPrice",class:_vm.wordColor,staticStyle:{"width":"23%","text-align":"right"}},[_vm._v(" "+_vm._s(item.memberPrice)+" ")])])}),0),(
                  _vm.showInfo &&
                  _vm.showInfo.roomTypes &&
                  _vm.showInfo.roomTypes.length >= 6
                )?_c('ul',{staticClass:"roomUl"},_vm._l((_vm.showInfo.roomTypes),function(item){return _c('li',{key:item.roomTypeId,class:_vm.liColor},[_c('div',{staticStyle:{"width":"57%","text-align":"left"}},[_vm._v(" "+_vm._s(item.roomTypeName)+" ")]),_c('div',{staticStyle:{"width":"20%","text-align":"right"}},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"memberPrice",class:_vm.wordColor,staticStyle:{"width":"23%","text-align":"right"}},[_vm._v(" "+_vm._s(item.memberPrice)+" ")])])}),0):_vm._e()])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right-header",class:_vm.bgc === 'orange' ? '' : 'white-word'},[_c('div',[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.currentDate)+" "+_vm._s(_vm.currentTime)+" "+_vm._s(_vm.currentDay)+" ")])]),_c('div')]),_c('a-carousel',{staticStyle:{"width":"100%"},attrs:{"autoplay":""}},_vm._l((_vm.showInfo.roomTypes),function(item){return _c('img',{key:item.roomTypeId,staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":item.image,"alt":""}})}),0),_c('div',{staticClass:"footer",class:_vm.footerColor},[_vm._v(" 订房热线："+_vm._s(_vm.showInfo.phone)+" ")])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }