<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data() {
    return {
      locale: zhCN
    }
  },
  computed: {
    getPath() {
      return this.$route.path
    }
  },
  watch: {
    getPath: {
      deep: true,
      handler: function (n, v) {
        if (n.indexOf('/index') !== -1) {
          this.$store.state.fouthName = '首页'
          this.$store.state.flag = false
        } else if (n.indexOf('/article/add') !== -1) {
          this.$store.state.fouthName = '新增文章'
          this.$store.state.flag = false
        } else if (n.indexOf('/article/update') !== -1) {
          this.$store.state.fouthName = '编辑文章'
          this.$store.state.flag = false
        } else {
          this.$store.state.flag = false
        }
      }
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.ant-layout {
  background: #f0f2f5 !important;
}

body,
html {
  background-color: #f0f2f5; /* 浅灰色 */
}
</style>
