<template>
  <a-sub-menu slot="title" class="menu-title">
    <div>
      <span :id="useDirectory.id">{{ useDirectory.name }}</span>
      <a-icon type="edit" />
    </div>
    <a-menu-item
      v-for="item in useDirectory.directories"
      :key="item.id"
      :id="item.id"
    >
      {{ item.name }}
      <a-icon type="edit" />
    </a-menu-item>
  </a-sub-menu>
</template>

<script>
import { getDirectoriesList, getModuleList } from '@/api'
import local from '@/utils/local'
export default {
  props: {
    directory: {
      type: Object
    }
  },
  data() {
    return {
      moduleList: '',
      treeData: [],
      defaultValue: [],
      id: '',
      useDirectory: null
    }
  },
  created() {},
  methods: {},

  computed: {
    getId() {
      return this.directory
    }
  },
  watch: {
    getId: {
      deep: true,
      immediate: true,
      handler: function (n, v) {
        this.useDirectory = n
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
