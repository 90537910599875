export default {
  set(k, v) {
    window.sessionStorage.setItem(k, v)
  },
  get(k) {
    return window.sessionStorage.getItem(k)
  },
  remove(k) {
    window.sessionStorage.removeItem(k)
  },
  clear() {
    window.sessionStorage.clear()
  },
  UrlParm(name) {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = window.location.search.substr(1).match(reg)
    // if (r != null) return unescape(r[2]);
    if (r != null) return decodeURIComponent(r[2])
    return null
  }
}
